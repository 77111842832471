import React, { useState } from 'react'
import projectStyle from './styles/project.module.css'

const Projectpage = project => {
  const [isActive, setActive] = useState(true);
  const clickFlip = () => {
    setActive(!isActive)
  }
  return (
  <div onClick={clickFlip} className={isActive ? projectStyle.flipCard: projectStyle.clickFlip }>
    <div className={projectStyle.flipCardInner}>
      <div className={projectStyle.flipCardFront}>
        <h3 className={projectStyle.projectTitleFront}>{project.project.name}</h3>
        <img className={projectStyle.imagestyle} src={project.project.image} alt="Avatar"  />
        <p className={projectStyle.shortdescription}>{project.project.shortDescription}</p>
      </div>
      <div className={projectStyle.flipCardBack}>
        <h3 className={projectStyle.projectTitleBack}>{project.project.name}</h3>
        <p className={projectStyle.description}>{project.project.description}</p>
        <div className={projectStyle.buttons}>
          <a href={project.project.url} target="_blank" rel="noreferrer">
            <button className={projectStyle.projectButton}>Project</button>
          </a>
          <a href={project.project.githuburl} target="_blank" rel="noreferrer">
            <button className={projectStyle.projectButton} >Github</button>
          </a>
        </div>
      </div>
    </div>
  </div> 
  )
}

export default Projectpage