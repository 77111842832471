import React from 'react'
import socialStyle from './styles/social.module.css'

const socialicons = social => {
  return (
    <div>
      <a href={social.social.url} target="_blank" rel="noreferrer"><img className={socialStyle.socialIcons} src={social.social.icon} alt='{social.social.name} icon'/></a>
    </div>
  )
}

export default socialicons