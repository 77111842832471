import React from 'react'
import popupStyle from './styles/popup.module.css'
import { NavHashLink as Link } from 'react-router-hash-link'
import close from '../assets/images/close-button.png'

const popupMenu = () => {
  return (
    <div className={popupStyle.popupContainer}>
      <Link className={popupStyle.closeContainer} to="/"><img className={popupStyle.closeButton} src={close} alt='logo'/></Link>
      <ul className={popupStyle.popupList}>
        <Link smooth to="/#Intro"><li className={popupStyle.computerMenu}>Intro</li></Link>
        <Link smooth to="/#Portfolio"><li className={popupStyle.computerMenu}>Portfolio</li></Link>
        <Link smooth to="/#About"><li className={popupStyle.computerMenu}>About</li></Link>
        <Link smooth to="/#Contact"><li className={popupStyle.computerMenu}>Contact</li></Link>
      </ul>
    </div>
  )
}

export default popupMenu