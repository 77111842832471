import React from 'react'
import Social from './socialicons'
import introStyles from './styles/intro.module.css'
import {socialIcons} from '../containters/socialIcons.js'
import { InView } from 'react-intersection-observer';

const intro = ({handleChange}) => {

  return (
      <InView onChange={(inView) => handleChange('Intro', inView)} threshold={0.5} id='Intro' className={introStyles.introContainer}>
        <h1 className={introStyles.title}>Michael Threels</h1>
        <p className={introStyles.introduction}>I'm a remote fullstack developer based in Poland I can make your website or web app look stunning and a joy to navigate contact me if you're interested</p>
        <ul className={introStyles.socialIconscontainer}>
          {socialIcons.map(social => <li  key={social.name} ><Social social={social} /></li>)}
        </ul>
      </InView>
  )
}

export default intro