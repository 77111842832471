import React from 'react'
import Social from './socialicons'
import {socialIcons} from '../containters/socialIcons.js'
import footerStyles from './styles/footer.module.css'

const Footer = () => {
  return (
    <div className={footerStyles.footerContainer}>
      <ul className={footerStyles.socialIconscontainer}>
        {socialIcons.map(social => <li  key={social.name} ><Social social={social} /></li>)}
      </ul>
    </div>
  )
}

export default Footer