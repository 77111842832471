import React from 'react'
import Projectpage from './projectpage'
import {portfolioProjects} from '../containters/portfolioprojects.js'
import projectStyle from './styles/project.module.css'
import { InView } from 'react-intersection-observer';

const Projects = ({handleChange}) => {

  return (
      <InView onChange={inView => handleChange('Projects', inView)} threshold={0.3} id='Portfolio' className={projectStyle.projectContainer}>
        <h2 className={projectStyle.mainHeader}>Projects</h2>
          <ul className={projectStyle.cardContainer}>
            {portfolioProjects.map(project => <li key={project.name}><Projectpage project={project} /></li>)}
          </ul>
      </InView>
  )
}

export default Projects