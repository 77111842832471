/* eslint-disable no-sequences */
import React, { useState } from 'react'
import logo from '../assets/images/Logo.png'
import menu from '../assets/images/menu-icon.png'
import navstyles from './styles/navbar.module.css'
import { Link } from "react-router-dom"
import Welcome from './intro'
import Projects from './projects'
import About from './aboutme'
import Contact from './contact'

const Navbar = () => {
  const [isStatus, setStatus] = useState({
    Intro: true,
    Projects: false,
    About: false,
    Contact: false
  })
  const handleChange = (page, status) => {
    // const current = page
    let tempStatus = {}
    tempStatus[page] = status
    setStatus(isStatus => ({
      ...isStatus,
      ...tempStatus
    }))
  }


  return (
    <div>
      <nav className={navstyles.navcontainer}>
        <ul className={navstyles.navList}>
          <li><a href='/'><img className={navstyles.logo} src={logo} alt='logo'/></a></li>
          <a href='/'  onClick={e => { let hero = document.getElementById("Intro"); e.preventDefault(); hero && hero.scrollIntoView({ behavior: "smooth"});}}><li className={isStatus.Intro ? navstyles.sectionActive : navstyles.computerMenu}>Intro</li></a>
          <a href='/'  onClick={e => { let hero = document.getElementById("Portfolio"); e.preventDefault(); hero && hero.scrollIntoView({ behavior: "smooth"});}}><li className={isStatus.Projects ? navstyles.sectionActive : navstyles.computerMenu}>Portfolio</li></a>
          <a href='/'  onClick={e => { let hero = document.getElementById("About"); e.preventDefault(); hero && hero.scrollIntoView({ behavior: "smooth"});}}><li className={isStatus.About ? navstyles.sectionActive : navstyles.computerMenu}>About</li></a>
          <a href='/'  onClick={e => { let hero = document.getElementById("Contact"); e.preventDefault(); hero && hero.scrollIntoView({ behavior: "smooth"});}}><li className={isStatus.Contact ? navstyles.sectionActive : navstyles.computerMenu}>Contact</li></a>
          <li className={navstyles.mobileMenu}><Link to="menu"><img className={navstyles.burgerMenu} src={menu} alt='menu icon'/></Link></li>
        </ul>
      </nav>
      <Welcome handleChange={handleChange} />
      <Projects handleChange={handleChange} />
      <About handleChange={handleChange} />
      <Contact handleChange={handleChange} />
    </div>
  )
}

export default Navbar