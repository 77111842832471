import React from 'react'
import aboutStyle from './styles/about.module.css'
import { InView } from 'react-intersection-observer';

const aboutme = ({handleChange}) => {
  return (
      <InView onChange={(inView) => handleChange('About', inView)} threshold={0.6} id='About' className={aboutStyle.aboutContainer}>
        <h2 className={aboutStyle.mainHeader}>About</h2>
        <p className={aboutStyle.intro}>I'm a passionate father/full stack developer I can help build your product, feature or website. Have a look at my previous projects and don't hesitate to contact me if you see anything you like</p>
        <form target="_blank" action="https://docs.google.com/document/d/1zU_czY6CbcMGPCUYkEQFHZsBjX2TpcpCJpl7-coTPCI/edit?usp=sharing">
          <input className={aboutStyle.resumeButton} type="submit" value="Get my Resume" />
        </form>
        <div className={aboutStyle.aboutInfo}>
          <div className={aboutStyle.languageContainer}>
            <h3>Languages</h3>
            <ul className={aboutStyle.list}>
              <li>HTML</li>
              <li>CSS</li>
              <li>Ruby</li>
              <li>Javascript</li>
            </ul>
          </div>
          <div className={aboutStyle.frameworkContainer}>
            <h3>Frameworks</h3>
            <ul className={aboutStyle.list}>
              <li>React</li>
              <li>Ruby on Rails</li>
              <li>Bootstrap</li>
              <li>Rspec</li>
            </ul>
          </div>
          <div className={aboutStyle.skillContainer}>
            <h3>Skills</h3>
            <ul className={aboutStyle.list}>
              <li>Github</li>
              <li>Terminal</li>
              <li>Git</li>
              <li></li>
            </ul>

          </div>
        </div>
      </InView>
  )
}

export default aboutme