import linkedinIcon from '../assets/images/linkedin-icon.png'
import githubIcon from '../assets/images/Github-icon.png'
import mediumIcon from '../assets/images/medium-icon.png'
import twitterIcon from '../assets/images/Twitter-icon.png'

export const socialIcons = [
  {
    name: "linkedIn",
    icon: linkedinIcon,
    url: "https://www.linkedin.com/in/michael-threels/"
  },
  {
    name: "github",
    icon: githubIcon,
    url: "https://github.com/mikethreels"
  },
  {
    name: "medium",
    icon: mediumIcon,
    url: "https://medium.com/@michaelthreels"
  },
  {
    name: "twitter",
    icon: twitterIcon,
    url: "https://twitter.com/MichaelThreels"
  },
]
