import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import contactStyle from './styles/contact.module.css'
import { InView } from 'react-intersection-observer';

const Contact = ({handleChange}) => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [send, setSend] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
    window.dataLayer.push({
      event: 'contact',
      eventProps: {
        action: "submit form",
        category: "contact",
        email: Email,
        name: Name
      }
    })
    emailjs.sendForm('service_roc1zum', 'template_pax6f3j', '#myForm', 'RSk0G8U6bF03fa_0J')
      .then(() => {
          console.log("success")
          setSend(true)
          setStatus(true)
      }, () => {
          setSend(true)
          setStatus(false)
      });
  };

  return (
      <InView onChange={(inView) => handleChange('Contact', inView)} threshold={0.6} id='Contact' className={contactStyle.contactContainer}>
        <h2 className={contactStyle.mainHeader}>Contact</h2>
        <div className={contactStyle.formContainer}>
          <form id='myForm' className={contactStyle.contactForm} onSubmit={sendEmail}>
            <div>
              <input className={contactStyle.nameInput} type="text" name="user_name" id="first_name" placeholder="Name" maxLength="30" required onChange={(e) => setName(e.target.value)} value={Name} />
            </div>
            <div>
              <input className={contactStyle.emailInput} type="email" name="user_email" id="email_address" placeholder="Email" required onChange={(e) => setEmail(e.target.value)} value={Email} />
            </div>
            <div>
              <textarea className={contactStyle.textInput} name="message" id="information" cols="30" rows="10" placeholder="Message" maxLength="500" required></textarea>
            </div>
            <input value="Reach out" type="submit" className={contactStyle.contactButton} />
          </form>
          {send ? <p className={status ? contactStyle.formSubmissionSuccess : contactStyle.formSubmissionError}>successfully submitted!</p> : <div></div> }
          
        </div>
      </InView>
  )
}

export default Contact