import reservateimg from '../assets/images/Reservate.png'
import fomula1img from '../assets/images/formula1.png'
import bookcoverimg from '../assets/images/book_cover_snip.png'
import mottoximg from '../assets/images/capstone_snip.png'

export const portfolioProjects = [
  {
    name: "Reservate",
    shortDescription: "A restaurant reservations page",
    description: "With this application, you'll be able to organise reservations as a restaurant owner and make reservations as a user.",
    image: reservateimg,
    url: "https://reservate-mt.herokuapp.com/",
    githuburl: "https://github.com/mikethreels/reservate"
  },
  {
    name: "Formula 1",
    shortDescription: "A formula 1 information page",
    description: "With this application, you'll be able to find all the current drivers on the grid, their points and the constructors they're driving for.",
    image: fomula1img,
    url: "https://formula-1-6d2c9.web.app",
    githuburl: "https://github.com/mikethreels/formula-1"
  },
  {
    name: "Book Cover",
    shortDescription: "A blog app about books",
    description: "This is a blog app with as theme programming books, you want to write about a programming book or read about the best programming books, you can use this app.",
    image: bookcoverimg,
    url: "https://young-ravine-05224.herokuapp.com/",
    githuburl: "https://github.com/mikethreels/book_cover"
  },
  {
    name: "Mottox",
    shortDescription: "online motorcycle parts shop",
    description: "In this project, I build an online shop using HTML & CSS. the project is repsonsive, mobile view will be visible under 768px and laptop view will be visible above 768px.",
    image: mottoximg,
    url: "https://mikethreels.github.io/capstone-project/",
    githuburl: "https://github.com/mikethreels/capstone-project"
  },
]
