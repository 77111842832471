import React from 'react'
import { Routes, Route } from "react-router-dom"
import Navbar from './Components/navbar'
import Footer from './Components/Footer'
import appStyles from './Components/styles/app.module.css'
import popupMenu from './Components/popupMenu'

function App() {
  window.dataLayer.push({
    event: 'pageview'
  })
  return (
    <div className={appStyles.mainContainer}>
      <Navbar />
      <Routes>
        <Route exact path="/" />
        <Route exact path="menu" Component={popupMenu}/>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
